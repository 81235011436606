import React from 'react'
import './Ribbons.css'

const brandsUtil = [
    'joico',
    'wella',
    'loreal',
    'glynett',
    'alfaparf',
]

const works = [
    'Botox Capilar',
    'Cauterização',
    'Penteados',
    'Sombrancelha',
    'Maquiagem',
    'Hidratação',
    'Luzes',
    'Babyliss',
]

const Ribbons = (props) => {
    if (props.type === 'brands') {
        return (
            <div className="ribbons">
                <span className='ribbonsTitles'>Algumas marcas:</span>
                <div className='ribbonsContainer'>
                    {brandsUtil.map((item, index) => {
                        return (
                            <div key={index} className="ribbonsBrands">
                                <img className="brands" src={process.env.PUBLIC_URL + `/assets/marcas/${item}.png`} alt={item} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    } else if (props.type === 'works') {
        return (
            <div className="ribbons">
                <span className='ribbonsTitles'>Outros serviços:</span>
                <div className="ribbonsContainer">
                    {works.map((item, index) => {
                        return (
                            <p key={index} className="works">{item}</p>
                        )
                    })}
                    <p className="works">E mais, confira!</p>
                </div>
            </div>
        )
    }
}

export default Ribbons