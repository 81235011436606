import React from 'react'
import './Services.css'
import Constants from './constants'

const servicesDates = [
    {
        title: 'Cortes', image: 'pexels-cottonbro-3993466.jpg', name: 'Cortes',
        description: 'Temos um catálogo variado de cortes, venha conferir!'
    },
    {
        title: 'Progressiva', image: 'pexels-polina-tankilevitch-3738359.jpg', name: 'Escova Progressiva',
        description: 'Escova progressiva com química de qualidade!'
    },
    {
        title: 'Tintura', image: 'pexels-cottonbro-3993145.jpg', name: 'Tinturas',
        description: 'Tinturas variadas das melhores marcas!'
    },
    {
        title: 'Manicure', image: 'pexels-cottonbro-3997386.jpg', name: 'Manicure e Pedicure',
        description: 'Muitos esmaltes para sua escolha!'
    },
]

const Services = () => {
    return (
        <div id="services">
            <h2><i className="fa-solid fa-scissors"></i></h2>
            <section id="servicesContainer">
                <div className="imgContainer">
                    {servicesDates.map((itens, index) => {
                        return (
                            <div key={index} className="imgServices">
                                <img src={process.env.PUBLIC_URL + '/assets/img/' + itens.image} alt={itens.title}/>
                                <i class="fa-solid fa-angles-up"></i>
                                <p className="serviceName">{itens.name}</p>
                                <p className="textServices">{itens.description}</p>
                            </div>
                        )
                    })}
                </div>
                <section className="galeryLink">
                    <p>Veja nossa galeria no Instagram e confira nossas postagens de alguns trabalhos realizados:</p>
                    <br />
                    <a className="buttonLink" href={Constants.instagram} target="_blank" rel="noopener noreferrer">Abrir Galeria</a>
                </section>
            </section>
        </div>
    )
}

export default Services