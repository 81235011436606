import React from 'react'
import './Home.css'
import { useSpring, animated } from 'react-spring'



const LinhaHome = (props) => {
    const styles = useSpring({
        delay: 1300,
        config: { duration: props.duration },
        loop: true,
        from: { x: 200, opacity: 0 },
        to: { x: 0, opacity: 1 },
    })

    return (
        <animated.div className="textHome" style={{ ...styles }}>{props.name}</animated.div>
    )
}

const Home = () => {
    return (
        <div id='home'>
            <h1>Salão de Beleza RL Cabelos Aclimação</h1>
            <h2><i className="fa-solid fa-house-chimney"></i></h2>
            <section className="sectionHome">
                <p className="homeTextTitle">RL CABELOS ACLIMAÇÃO</p>
                <LinhaHome name="15 anos na Aclimação!" duration="2500" />
                <LinhaHome name="Produtos de qualidade!" duration="3500" />
                <LinhaHome name="Preços competitivos na região!" duration="4500" />
            </section>
        </div>
    )
}

export default Home