import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer id="footerInfo">
            <span>RL Cabelos Aclimação 2022 &copy;. Todos direitos reservados. Imagens ilustrativas dos trabalhos disponíveis - Imagens retiradas
                no <a href="https://www.pexels.com/pt-br/" target="blank"> site pexels</a>.</span>
        </footer>
    )
}

export default Footer