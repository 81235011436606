import React, { useState } from 'react'
import { Link } from 'react-scroll'
import './NavMenu.css'


const navLinks = [
    { link: "home", iClassName: "fa-solid fa-house-chimney", name: " Home" },
    { link: "about", iClassName: "fa-solid fa-book", name: " Sobre" },
    { link: "services", iClassName: "fa-solid fa-scissors", name: " Serviços" },
    { link: "contact", iClassName: "fa-solid fa-address-book", name: " Contato" },
]

const NavMenu = () => {

    const [menuClicked, setMenuClicked] = useState(false)
    const toggleMenu = () => {
        setMenuClicked(!menuClicked)
    }

    return (
        <div className="menuContent">
            {/* <a href="#home">
                <div id="logoWrite">RL CABELEIREIROS</div>
            </a> */}
            <Link to='home' smooth={true} duration={1000}>
                <div id="logoWrite">RL CABELOS</div>
            </Link>

            <div className={menuClicked ? 'menuHamburguer active' : 'menuHamburguer'} onClick={toggleMenu}>
                <div id="line1"></div>
                <div id="line2"></div>
                <div id="line3"></div>
            </div>

            <nav className={menuClicked ? 'navMenu active' : 'navMenu'} >
                {navLinks.map((item, index) => {
                    return (
                        <Link key={index} activeClass="active" to={item.link} spy={true} smooth={true} duration={1000}
                            className="itensMenu" onClick={toggleMenu}>
                            <i className={item.iClassName}></i> {item.name}
                        </Link>
                    )
                })}
            </nav>

        </div>
    )
}

export default NavMenu
