import React from 'react'
import './Contact.css'
import { Link } from 'react-scroll'
import Constants from './constants'


const Contato = () => {
    return (
        <div id="contact">
            <h2><i className="fa-solid fa-address-book"></i></h2>
            <div id="contactContainer">
                <section className="contactLogos">
                    <img id="logoRL" src={process.env.PUBLIC_URL + "/assets/img/Rl cabelereiros Imagem Feminine.jpg"} alt="logo RL" />
                    <Link to='home' smooth={true} duration={1000}>
                        <div id="logoWrite">RL CABELOS</div>
                    </Link>
                </section>
                <section id="contactDates">
                    <p className="contactHighlights">ENTRE EM CONTATO:</p>
                    <p><i className="fa-solid fa-square-phone"></i>(11) 3209-3947</p>
                    <p><a href={Constants.whatsApp} target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-whatsapp"></i> (11) 9 8787-0314</a></p>
                    <p> <a href={Constants.instagram} target="_blank" rel="noopener noreferrer">
                        <i class="fa-brands fa-instagram-square"> </i>@rl_cabelos_aclimacao</a></p>
                </section>
                <section id="addresContainer">
                    <p className="contactHighlights">FAÇA UMA VISITA:</p>
                    <p><a href="https://goo.gl/maps/dzaFuGujFDpXYGdj9" target="blank">
                        <i className="fa-solid fa-location-dot"> </i>
                        Rua Muniz de Sousa nº 874 - Aclimação/SP - BR CEP 01534-001.
                        <br />  Clique para ver o mapa.
                    </a></p>
                    <p className="contactHighlights">HORÁRIOS:</p>
                    <p>Terças a sabados das 9h as 18h</p>
                    <p className="contactHighlights">PAGAMENTOS:</p>
                    <p>Pagamentos em débito, PIX ou dinheiro</p>
                </section>
            </div>
        </div>
    )
}

export default Contato