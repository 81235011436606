import React from 'react'
import './App.css'

import Home from './components/Home'
import NavMenu from './components/NavMenu'
import About from './components/About'
import Ribbons from './components/Ribbons'
import Services from './components/Services'
import Contact from './components/Contact'
import Footer from './components/Footer'

function App() {

  return (
    <div className="App">
      <NavMenu />
      <Home />
      <About />
      <Ribbons type='brands' />
      <Services />
      <Ribbons type='works' />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
