import React from 'react'
import './About.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

var Carousel = require('react-responsive-carousel').Carousel;

const textsAbout = [
    'Estamos a cerca de 15 anos trabalhando na região da Aclimação.',
    'Clientes fiéis que sempre retornam por amarem nosso trabalho permitem que tenhamos essa longevidade no bairro.',
    'Fazemos serviços qualificados, com excelencia em proteger a saúde do seu cabelo!',
    'Manicure e pedicure muito experiente!',
    'Fazemos penteados e maquiagens para casamentos e festas!',
    'Temos diferentes serviços para você, venha conferir!',
    'Marque um horário, estamos aguardando!',
]

const BoxTextsAbout = () => {
    return (
        <div className="aboutText" >
            {textsAbout.map((text, index) => {
                return (
                    <p className="boxTextAbout" key={index} >
                        <span className="highlightsText">{text.substring(0, 1)}</span>{text.substring(1)}
                    </p>
                )
            })}
        </div>
    )
}


const CarouselAbout = () => {

    return (
        <Carousel showArrows={false} autoPlay={true} infiniteLoop={true} emulateTouch={true} interval={3000} showThumbs={false} showStatus={false} >
            <div>
                <img src={process.env.PUBLIC_URL + "/assets/carousel/pexels-alexandr-podvalny-341372.jpg"} alt="carousel 01" />
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "/assets/carousel/pexels-pixabay-247322.jpg"} alt="carousel 02" />
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "/assets/carousel/pexels-engin-akyurt-3331486.jpg"} alt="carousel 03" />
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "/assets/carousel/pexels-cottonbro-6144281.jpg"} alt="carousel 04" />
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "/assets/carousel/pexels-cottonbro-6941115.jpg"} alt="carousel 05" />
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "/assets/carousel/pexels-pixabay-355063.jpg"} alt="carousel 06" />
            </div>
        </Carousel>
    )
}


const About = () => {
    return (
        <div id="about">
            <h2><i className="fa-solid fa-book"></i></h2>
            <div id="aboutContainer">
                <section id="aboutLeft">
                    <BoxTextsAbout />
                </section>
                <section id="aboutRight">
                    <div className="aboutImages">
                        <CarouselAbout />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default About